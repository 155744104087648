.main > div > div {
  padding: 0;
}

h1 {
  font-size: clamp(3rem, 10vw, 12rem);
  color: #7bab4f;
}

.chara_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.chara_name {
  font-size: clamp(2rem, 7vw, 4rem);
}

.image {
  /* width: 100%; */
}

@media (max-width: 768px) {
  .chara_container {
    grid-template-columns: 1fr;
  }
}
